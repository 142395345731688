let filter = f => {
  if (!('condition' in f)) return true
  if (typeof f.condition === 'function') return f.condition()
  return f.condition
}

export default function() {
  const get = path => this.$path(path, this.$store.state)
  const geti18n = (key, index = 1) => this.$tc('floorplan', index)
  return [
    {
      component: 'Section',
      condition: get('Residential.item.name'),
      props: {
        width: 'small',
        id: 'section-header',
        class: 'section-header',
        style: 'transform: translateX(-5rem); padding:9rem 0',
      },
      blocks: {
        default: {
          component: 'Grid',
          props: {
            gap: '2rem 8rem',
          },
          blocks: {
            default: [
              {
                component: 'Summary',
                props: {},
                data: {
                  content: get('Residential.item'),
                },
              },
              {
                component: 'Floorplans',
                props: {},
                data: {
                  content: [
                    ...(get('Residential.item.media.cover') || []),
                    ...(get('Residential.item.media.floorplan') || []),
                  ],
                },
              },
            ],
          },
        },
      },
    },
    {
      component: 'Section',
      condition: get('Residential.item.customFields.iframe-embed'),
      props: {
        width: 'medium',
        id: 'section-iframe-embed',
        class: 'section-iframe-embed',
        style: 'background-color:var(--primary);',
      },
      blocks: {
        default: [
          {
            component: 'Embed',
            data: {
              src: get('Residential.item.customFields.iframe-embed'),
            },
          },
        ],
      },
    },

    {
      component: 'Section',
      condition:
        get('Residential.item.shop.published') && !KvassConfig.get('siteSettings.hideShop'),
      props: {
        width: 'medium',
        id: 'section-shop-intro',
        class: 'section-shop-intro',
      },
      blocks: {
        default: [
          {
            component: 'ScrollAnchor',
            data: 'shop',
          },
          {
            component: 'ShopIntro',
            data: {
              residential: get('Residential.item'),
            },
          },
        ],
      },
    },

    {
      component: 'Section',
      condition: [
        ...(get('Residential.item.media.attachments') || []),
        ...(get('Residential.item.project.media.attachments') || []),
      ].length,
      props: {
        width: 'medium',
        id: 'section-attachments',
        class: 'section-attachments',
      },
      blocks: {
        default: [
          {
            component: 'ScrollAnchor',
            data: 'attachments',
          },
          {
            component: 'Attachments',
            data: [
              ...(get('Residential.item.media.attachments') || []),
              ...(get('Residential.item.project.media.attachments') || []),
            ],
          },
        ],
      },
    },

    {
      component: 'Section',
      props: {
        width: 'medium',
        id: 'section-flatfinder',
        class: 'section-flatfinder',
        style: 'padding-bottom:1rem;',
      },
      blocks: {
        default: [
          {
            component: 'ScrollAnchor',
            data: 'flatfinder',
          },
          {
            component: 'Flatfinder',
            data: {
              flatfinders: get('Residential.item.project.flatfinders'),
            },
          },
        ],
      },
    },

    {
      component: 'Section',
      props: {
        width: 'medium',
        id: 'section-residentials',
        class: 'section-residentials',
        style: 'padding-top:1rem',
      },
      blocks: {
        default: [
          {
            component: 'ScrollAnchor',
            data: 'residentials',
          },
          {
            component: 'Residentials',
            data: {
              project: get('Residential.item.project.id'),
            },
          },
        ],
      },
    },

    {
      component: 'Section',
      props: {
        width: 'large',
        class: 'section-gallery',
      },
      blocks: {
        default: [
          {
            component: 'Gallery',
            variant: 'grid',

            data: {
              images: get('Residential.item.media.gallery'),
            },
          },
        ],
      },
    },
    {
      component: 'Section',
      condition: get('Residential.item.project.id'),
      props: {
        width: 'medium',
        id: 'section-contact',
        class: 'section-contact',
      },
      blocks: {
        default: [
          {
            component: 'ScrollAnchor',
            data: 'lead',
          },
          {
            component: 'Grid',
            props: {
              gap: '4rem 8rem',
              columns: '2:1',
            },
            blocks: {
              default: [
                {
                  component: 'Lead',
                  props: {
                    options: {
                      placeholders: {
                        'contact.name': this.$t('name'),
                        'contact.email': this.$t('email'),
                        'contact.phone': this.$t('phone'),
                      },
                      fields: ['!title'],
                      actionLabel: this.$t('send'),
                    },
                  },
                  data: {
                    project: get('Residential.item.project.id'),
                    reference: { onModel: 'Residential', ref: get('Residential.item.id') },
                  },
                  blocks: {
                    top: [
                      {
                        component: 'Text',
                        data: {
                          content: get('Residential.item.project.customFields.lead-top-content'),
                        },
                      },
                    ],
                  },
                },
                {
                  component: 'Roles',
                  props: {},
                  data: {
                    content: get('Residential.item.project.roles.salesman'),
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ].filter(f => !('condition' in f) || f.condition)
}
